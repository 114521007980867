<template lang="pug">
  tr.sold-out-item
    td.name {{ item.reference_name }}
    td.name {{ item.car_class_name }}
    td
      .sold-out-days
        .days(ref="soldOutDays")
          TransitionGroup(
            name="fade-zoom"
            appear
            tag="div"
            class="transition-container"
          )
            span(
              v-for="date in slicedItems"
              :key="date"
              @click="$emit('clicked', date)"
            ) {{ formattedDate(date) }}
        .show-more(
          v-if="item.dates.length > maxItems"
          @click="showAll = !showAll"
        )
          span(v-if="!showAll")
            | {{ `+${item.dates.length-maxItems}` }}
          FaIcon(:icon="arrowIcon")
</template>

<script>
  import { format as dateFormat } from "date-fns"

  import { DATE_SHORT_DATE_FORMAT } from "@/config/constants"

  export default {
    props: {
      item: Object
    },

    data() {
      return {
        showAll: false,
        maxItems: 0
      }
    },

    mounted() {
      this.calculateMaxItems()

      this.$nextTick(() => {
        window.addEventListener("resize", this.calculateMaxItems)
      })
    },

    beforeDestroy() {
      window.removeEventListener("resize", this.calculateMaxItems)
    },

    computed: {
      arrowIcon() {
        return this.showAll ? "chevron-up" : "chevron-down"
      },

      slicedItems({ item, maxItems, showAll }) {
        if (showAll) return item.dates

        return item.dates.slice(0, maxItems)
      }
    },

    methods: {
      formattedDate(date) {
        return dateFormat(new Date(date), DATE_SHORT_DATE_FORMAT)
      },

      calculateMaxItems() {
        const soldOutDaysEl = this.$refs.soldOutDays

        this.maxItems = soldOutDaysEl ? parseInt(soldOutDaysEl.offsetWidth / 55) : 0
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/transitions.sass"

  .sold-out-item
    font-size: 0.8rem
    height: 43px

    td
      padding: 0 10px
      border: 1px solid $default-purple-light-line
      border-top: none

      &.name
        width: 300px
      .sold-out-days
        display: flex
        align-items: center

        .days
          display: flex
          align-items: center
          flex-wrap: wrap
          width: 95%

          span
            color: $default-blue-dark
            background-color: $default-blue-light
            margin: 5px 10px 5px 0px
            padding: 4px 0
            border-radius: 10px
            cursor: pointer
            width: 45px
            display: flex
            justify-content: center

        .show-more
          border-radius: 8px
          width: 45px
          height: 27px
          display: flex
          justify-content: center
          align-items: center
          padding: 4px 0
          cursor: pointer
          background-color: $default-orange

          span
            margin-right: 5px

          svg
            width: 9px
</style>
